import React  from 'react';

// import icons
import {FaLinkedin} from 'react-icons/fa';
import {BsChatDotsFill } from 'react-icons/bs';

// import images
import AboutImg from './assets/img/about.png';
import Feature1Img from './assets/img/features/feature1.png';
import Feature2Img from './assets/img/features/feature2.png';
import Feature3Img from './assets/img/features/feature3.png';
import Feature4Img from './assets/img/features/feature4.png';

import LogoV2 from './assets/img/logo4Dcell.png';
import HeroImage from './assets/img/hero-img.png';
import Feature1BgImg from './assets/img/features/feature1_bg.png';
import Feature2BgImg from './assets/img/features/feature2_bg.png';
import Feature3BgImg from './assets/img/features/feature3_bg.png';
import Feature4BgImg from './assets/img/features/feature4_bg.png';

export const navigationData = [
    {
        name: 'About',
        href: '#',
    },
    {
        name: 'Help',
        href: '#'
    },
    {
        name: 'Features',
        href: '#'
    },
    {
        name: 'Signup',
        href: '#',
    },
];

export const heroData = {
    title: 'SmartHeart Platform',
    subtitle:
    'Achieve a breakthrough number of readouts in a single assay and learn more about cardiac organoids. ',
    btnText: 'Learn more ',
    image: HeroImage,
};

export const aboutData = {
    image: AboutImg,
    title: '4Dcell, explore better',
    subtitle: '4Dcell is a an innovative company, pioneer in the promotion of easy access to up-to-date technologies fo the control of cell micronenvironments.'
};

export const featuresData = {
    title: 'Features',
    subtitle: 'Some of the features and advantages that we provide for those of you who choose the Smartheart Platform',
    list: [
    {
      image: Feature1Img,
      bgImage: Feature1BgImg,
      title: 'Search Data',
      description:
        'Don’t worry if your data is very large, the Data Warehouse provides a search engine, which is useful for making it easier to find data effectively saving time.',
      linkText: 'Learn more',
      delay: '400',
    },
    {
      image: Feature2Img,
      bgImage: Feature2BgImg,
      title: '24 Hours Access',
      description:
        'Access is given 24 hours a full morning to night and meet again in the morning, giving you comfort when you need data when urgent..',
      linkText: 'Learn more',
      delay: '700',
    },
    {
      image: Feature3Img,
      bgImage: Feature3BgImg,
      title: 'Print Out',
      description:
        'Print out service gives you convenience if someday you need print data, just edit it all and just print it.',
      linkText: 'Learn more',
      delay: '1000',
    },
    {
      image: Feature4Img,
      bgImage: Feature4BgImg,
      title: 'Security Code',
      description:
        'Data Security is one of our best facilities. Allows for your files to be safer. The file can be secured with a code or password than you created, so only you can open the file.',
      linkText: 'Learn more',
      delay: '1300',
    },
  ],

};



export const footerData = {
    logo: LogoV2,
    address: '14 rue de la beaune, 93100 Montreuil, FRANCE',
    email: 'contact@4dcell.com',
    phone: '+33 1 8425 1614 — Administration',

    list1: [
        {
      name: 'Profile',
      href: '#',
    },
    {
      name: 'Features',
      href: '#',
    },
    {
      name: 'Careers',
      href: '#',
    },
    {
      name: '4Dcell News',
      href: '#',
    },
  ],
  list2: [
    {
      name: 'Support',
      href: '#',
    },
    {
      name: 'Sign Up',
      href: '#',
    },
    {
      name: 'Guide',
      href: '#',
    },
    {
      name: 'Reports',
      href: '#',
    },
    {
      name: 'Q & A',
      href: '#',
    },
  ],
  socialList: [
    {
      icon: <FaLinkedin />,
      href: 'https://www.linkedin.com/company/4dcell/',
    },
    ]

};

export const copyrightData = {
    text: 'Copyright 2020 – 4DCell – All rights reserved',
    icon: <BsChatDotsFill/>
};