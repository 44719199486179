import React, { useEffect } from 'react';
// import components
import Hero from '../components/Hero';
import About from '../components/About';
import Footer from '../components/Footer';
import DropFile from '../components/dropFileInput_alt/DropFile';

const Demo = () => {
    useEffect(() => {
        // Set the background color of the body element to slightly off-white
        document.body.style.backgroundColor = 'rgb(246, 252, 225)';
    }, []);

  const onFileChange = (files) => {
    console.log(files)
  }

  return (
    <div className={'overflow-hidden'}>
    <Hero/>
    <div className="box">
      <h2 className='header'>
      </h2>
      <DropFile/>
    </div>
    <About/>
    <Footer/>
    </div>

  );
}

export default Demo;