// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;1,200&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  --body-bg:#f7f7f7;
  --box-bg:#fff;
  --input-bg:#f7f7f7;
  --txt-color:#000000;
  --txt-second-color:#000000;
  --border-color:#85b918;
  --box-shadow:rgba(149,157,164,.2) 0px 8px 24px ;
}

*{
  padding: 0;
  margin:0;
  box-sizing: border-box;

}

body{
  font-family: "Montserrat", sans-serif;
  font-weight:400;
  line-height: 1.5;
  background-color: var(--body-bg);
  color: var(--txt-color);
  display: flex;
  justify-content: center;
  padding-top: 100px;
  height: 100vh;
}

.box {
  background-color: var(--box-bg);
  padding: 100px;
  border-radius: 100px;
  box-shadow: var(--box-shadow);
  min-height: 200px; /* Adjust the initial minimum height as needed */
  transition: min-height 0.3s ease; /* Add smooth transition effect */
}

.scrollable-consent {
  height: 150px; /* Fixed height to limit how much space it takes */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  border: 1px solid #ccc; /* Optional: Add a border for better visibility */
  border-radius: 4px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,0BAA0B;EAC1B,sBAAsB;EACtB,+CAA+C;AACjD;;AAEA;EACE,UAAU;EACV,QAAQ;EACR,sBAAsB;;AAExB;;AAEA;EACE,qCAAqC;EACrC,eAAe;EACf,gBAAgB;EAChB,gCAAgC;EAChC,uBAAuB;EACvB,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,+BAA+B;EAC/B,cAAc;EACd,oBAAoB;EACpB,6BAA6B;EAC7B,iBAAiB,EAAE,gDAAgD;EACnE,gCAAgC,EAAE,iCAAiC;AACrE;;AAEA;EACE,aAAa,EAAE,kDAAkD;EACjE,gBAAgB,EAAE,8BAA8B;EAChD,aAAa;EACb,sBAAsB,EAAE,iDAAiD;EACzE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;1,200&display=swap');\r\n\r\n:root{\r\n  --body-bg:#f7f7f7;\r\n  --box-bg:#fff;\r\n  --input-bg:#f7f7f7;\r\n  --txt-color:#000000;\r\n  --txt-second-color:#000000;\r\n  --border-color:#85b918;\r\n  --box-shadow:rgba(149,157,164,.2) 0px 8px 24px ;\r\n}\r\n\r\n*{\r\n  padding: 0;\r\n  margin:0;\r\n  box-sizing: border-box;\r\n\r\n}\r\n\r\nbody{\r\n  font-family: \"Montserrat\", sans-serif;\r\n  font-weight:400;\r\n  line-height: 1.5;\r\n  background-color: var(--body-bg);\r\n  color: var(--txt-color);\r\n  display: flex;\r\n  justify-content: center;\r\n  padding-top: 100px;\r\n  height: 100vh;\r\n}\r\n\r\n.box {\r\n  background-color: var(--box-bg);\r\n  padding: 100px;\r\n  border-radius: 100px;\r\n  box-shadow: var(--box-shadow);\r\n  min-height: 200px; /* Adjust the initial minimum height as needed */\r\n  transition: min-height 0.3s ease; /* Add smooth transition effect */\r\n}\r\n\r\n.scrollable-consent {\r\n  height: 150px; /* Fixed height to limit how much space it takes */\r\n  overflow-y: auto; /* Enable vertical scrolling */\r\n  padding: 10px;\r\n  border: 1px solid #ccc; /* Optional: Add a border for better visibility */\r\n  border-radius: 4px;\r\n  margin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
