import React from "react";

// import hero data
import {heroData} from '../data.js'

const Hero=() => {

    // destructure hero data
    const {title, subtitle, btnText, image} = heroData

return (
    <section className={'lg:h-[500px] py-12 relative'}>
        <div className={"container mx-auto h-full"}>
            <div className={'flex flex-col xl:flex-row items-center h-full md:py-24'}>
                {/* text */}
                <div className='text-center xl:text-left absolute z-10'>
                    <h1 className='h1 xl:max-w-[500px] mb-6 xl:mb-12'
                        data-aos='fade-down'
                        data-aos-delay='500'
                        style={{ color: 'rgb(153, 204, 0)' , marginTop: '-265px' }}
                        > {title}
                        </h1>
                </div>
                {/* image */}
                <div className='absolute z-0 xl:right-0 xl:bottom-0'>
                    <img src={image}/>
                </div>
            </div>
        </div>
    </section>
);

    };

export default Hero;