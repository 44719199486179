import React, { useState } from 'react';
import { Text, TextInput, View, ScrollView } from 'react-native';
import { FilePond, registerPlugin } from 'react-filepond';
import { ClipLoader } from 'react-spinners';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import MaintenanceBanner from '../MaintenanceBanner'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DropFile = () => {
  const [email, setEmail] = useState('');
  const [inital_stiffness, setInitialStiffness] = useState('');
  const [daysinculture, setDaysInCulture] = useState('');
  const [video, setVideo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [fileCount, setFileCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);


  const handleCheckboxChange = (option) => {
    // If the clicked checkbox is already selected, uncheck it
    // Otherwise, set the selected option
    setSelectedOption(selectedOption === option ? null : option);
  };

  const onEmailChange = (text) => {
    setEmail(text);
  };

  const onInitialStiffnessChange = (text) => {
    setInitialStiffness(text);
  };

  const onDaysInCultureChange = (text) => {
    setDaysInCulture(text);
  };

  const onFileUpdate = (fileItems) => {
    setVideo(fileItems);
    setFileCount(fileItems.length);
  };

  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setErrors('Please enter a valid email address.');
      return;
    }

    if (video.length === 0) {
      setErrors('Please upload at least one video file.');
      return;
    }

    if (inital_stiffness === '') {
      setErrors('Please enter a value for the initial stiffness at day 0.');
      return;
    }

    if (daysinculture === '') {
      setErrors('Please enter a value for days in culture:');
      return;
    }

    if (selectedOption === null) {
    setErrors('Please indicate your preferences regarding video usage.');
    return;
    }

    // Convert the input values to numbers
    const initialStiffnessValue = parseFloat(inital_stiffness);
    const daysInCultureValue = parseFloat(daysinculture);

    // Check if the conversion was successful
    if (isNaN(initialStiffnessValue) || isNaN(daysInCultureValue)) {
      setErrors('Please enter valid numerical values for stiffness and days in culture.');
      return;
    }

    // Calculate the Young's modulus
    const young_modulus = initialStiffnessValue - 0.3366 * daysInCultureValue;

    // Prepare form data
    const formData = new FormData();
    formData.append('email', email);
    formData.append('young_modulus', young_modulus);
    formData.append('consent', selectedOption);

    for (let i = 0; i < video.length; i++) {
      formData.append('fileList', video[i].file);
    }

    const requestOptions = {
      method: 'POST',
      body: formData,
    };

    setLoading(true);

    fetch('https://backend-test-33qnuiqd6q-ew.a.run.app/upload', requestOptions)
      .then((response) => {
        setLoading(false);

        if (!response.ok) {
          return response.json().then((errorData) => {
            setErrors(errorData.detail || 'An error occurred.');
          });
        }

        // Reset the form
        setEmail('');
        setInitialStiffness('');
        setDaysInCulture('');
        setVideo([]);
        setSelectedOption(null);

        // Display success message
        alert('Thank you, the upload was successful. You will receive the results of the analysis of your video(s) by email shortly.');
        return response.json();
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrors('An error occurred. Please try again. If the error persists, please email us at: support@4dcell.com');
      });
  };

  const resetForm = () => {
    setEmail('');
    setVideo([]);
    setInitialStiffness('');
    setDaysInCulture('');
    setSelectedOption(null);
    setErrors('');
    setFileCount(0);
  };

  return (
    <View style={{ height: 300 + fileCount * 50, width: 400, margin: 'auto' }}>
      {loading ? (
        <View>
          <ClipLoader size={50} color={'#123abc'} loading={loading} />
          <Text>We are now uploading your video(s) for analysis, you can close this window if you'd like. You will receive the results by email shortly...</Text>
        </View>
      ) : (
        <View>

          <FilePond
            labelIdle={'Choose a file or drag it here'}
            credits={false}
            files={video}
            allowMultiple={true}
            instantUpload={false}
            onupdatefiles={onFileUpdate}
          />
          <Text>Please enter your email address:</Text>
          <TextInput
            name="email"
            value={email}
            onChangeText={onEmailChange}
            placeholder=" "
            inputMode="email"
          />
          <Text>Please enter the number of days in culture:</Text>
          <TextInput
            keyboardType="numeric"
            name="daysinculture"
            value={daysinculture}
            onChangeText={onDaysInCultureChange}
            placeholder=" "
          />
          <Text>Please enter the inital stiffness at day 0:</Text>
          <TextInput
            keyboardType="numeric"
            name="inital_stiffness"
            value={inital_stiffness}
            onChangeText={onInitialStiffnessChange}
            placeholder=" "
          />

          {/* Display the consent section after the user has entered input */}
          {email && inital_stiffness && daysinculture && video.length > 0 && (
           <View>
              <ScrollView style={{ height: 110, marginTop: 10, borderWidth: 1, borderColor: '#ccc', borderRadius: 4 }}>
                <Text style={{ marginBottom: 10 }}>
                  At 4Dcell, we are committed to protecting your content and ensuring clarity regarding how your videos will be used. Please read the following details carefully:
                </Text>
                <Text style={{ marginBottom: 10 }}>  <Text style={{ fontWeight: 'bold' }}>Approval Before Uploading:</Text> We will only proceed with uploading and utilizing your video content after you have given explicit consent. No content will be made public or used for any training materials without your clear approval.
                    </Text>
                <Text style={{ marginBottom: 10 }}> <Text style={{ fontWeight: 'bold' }}>Temporary Use for Analysis:</Text> Any video you submit to 4Dcell for initial review will be used exclusively for the purpose of analysis and evaluation. During this period, your videos will remain private and will not be used for any other purposes, including training or promotional use.
                    </Text>
                <Text style={{ marginBottom: 10 }}> <Text style={{ fontWeight: 'bold' }}>Optional Use for Training:</Text> If you agree to allow us to use your videos for training purposes, this will only occur after we receive your explicit consent.</Text>
                <Text style={{ marginBottom: 10 }}>Your privacy and control over your content are our top priorities.
                    </Text>

                <Text style={{ marginBottom: 10 }}>If you have any concerns or questions, please feel free to contact us at  <Text style={{ fontWeight: 'bold' }}>support@4dcell.com.</Text></Text>


              {/* Checkboxes for user approval */}
              <View>
                  <Text>
                    <Text>
                      <input
                        type="checkbox"
                        checked={selectedOption === 'temporaryAnalysis'}
                        onChange={() => handleCheckboxChange('temporaryAnalysis')}
                      />
                     {' '} {/* This space is to separate the checkbox and text */}
                    I approve the use of my videos for temporary analysis only.
                    </Text>
                    <br />
                    <Text>
                      <input
                        type="checkbox"
                        checked={selectedOption === 'trainingPurpose'}
                        onChange={() => handleCheckboxChange('trainingPurpose')}
                      />
                      {' '}
                     I approve the use of my videos for analysis <Text style={{ fontWeight: 'bold' }}>and</Text> training purposes.
                    </Text>
                  </Text>
                </View>
               </ScrollView>
            </View>
          )}

          <button
            type="submit"
            className="rounded-sm px-3 py-1 bg-pink-700 hover:bg-pink-500 text-white focus:shadow-outline focus:outline-none"
            title="Upload now"
            onClick={onSubmit}
            style={{ marginTop: 10 }}
          >
            Upload now
          </button>
          {errors && (
            <View>
              <Text style={{ color: 'red' }}>{errors}</Text>
              <button
                type="submit"
                className="rounded-sm px-3 py-1 bg-pink-700 hover:bg-pink-500 text-white focus:shadow-outline focus:outline-none"
                onClick={resetForm}
                style={{ marginTop: 10 }}
              >
                Reset and Upload Again
              </button>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default DropFile;
