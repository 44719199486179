import React, { useState } from 'react';
import { Text, TextInput, View } from 'react-native';
import { FilePond, registerPlugin } from 'react-filepond';
import { ClipLoader } from 'react-spinners';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import MaintenanceBanner from '../MaintenanceBanner'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DropFile = () => {
  const [email, setEmail] = useState('');
  const [young_modulus, setYoungModulus] = useState('');
  const [video, setVideo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [fileCount, setFileCount] = useState(0);

  const onEmailChange = (text) => {
    setEmail(text);
  };

  const onYoungModulusChange = (text) => {
    setYoungModulus(text);
  };

  const onFileUpdate = (fileItems) => {
    setVideo(fileItems);
    setFileCount(fileItems.length);
  };

  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setErrors('Please enter a valid email address.');
      return;
    }

    if (video.length === 0) {
      setErrors('Please upload at least one video file.');
      return;
    }

    if (young_modulus === '') {
      setErrors('Please enter a value for Young\'s modulus.');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('email', email);
    formData.append('young_modulus', young_modulus);

    for (let i = 0; i < video.length; i++) {
      formData.append('fileList', video[i].file);
    }

    const requestOptions = {
      method: 'POST',
      body: formData,
    };

    fetch('https://backend-test-33qnuiqd6q-ew.a.run.app/upload', requestOptions)
      .then((response) => {
        setLoading(false);

        if (!response.ok) {
          return response.json().then((errorData) => {
            setErrors(errorData.detail || 'An error occurred.');
          });
        }

        // Reset the form
        setEmail('');
        setYoungModulus('');
        setVideo([]);

        // Display success message
        alert('Thank you, the upload was successful. You will receive the results of the analysis of your video(s) by email shortly.');
        return response.json();
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrors('An error occurred. Please try again. If the error persists, please email us at: support@4dcell.com');
      });
  };

  const resetForm = () => {
    setEmail('');
    setVideo([]);
    setYoungModulus('');
    setErrors('');
    setFileCount(0);
  };

  return (
    <View style={{ height: 300 + fileCount * 50, width: 400, margin: 'auto' }}>
      {loading ? (
        <View>
          <ClipLoader size={50} color={'#123abc'} loading={loading} />
          <Text>We are now uploading your video(s) for analysis, you can close this window if you'd like. You will receive the results by email shortly...</Text>
        </View>
      ) : (
        <View>

          <FilePond
            labelIdle={'Choose a file or drag it here'}
            credits={false}
            files={video}
            allowMultiple={true}
            instantUpload={false}
            onupdatefiles={onFileUpdate}
          />
          <Text>Please enter your email address:</Text>
          <TextInput
            name="email"
            value={email}
            onChangeText={onEmailChange}
            placeholder=" "
            inputMode="email"
          />
          <Text>The Young's modulus value for the videos uploaded today:</Text>
          <TextInput
            keyboardType="numeric"
            name="young_modulus"
            value={young_modulus}
            onChangeText={onYoungModulusChange}
            placeholder=" "
          />
          <button
            type="submit"
            className="rounded-sm px-3 py-1 bg-pink-700 hover:bg-pink-500 text-white focus:shadow-outline focus:outline-none"
            title="Upload now"
            onClick={onSubmit}
            style={{ marginTop: 10 }}
          >
            Upload now
          </button>
          {errors && (
            <View>
              <Text style={{ color: 'red' }}>{errors}</Text>
              <button
                type="submit"
                className="rounded-sm px-3 py-1 bg-pink-700 hover:bg-pink-500 text-white focus:shadow-outline focus:outline-none"
                onClick={resetForm}
                style={{ marginTop: 10 }}
              >
                Reset and Upload Again
              </button>

            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default DropFile;
